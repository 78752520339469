import React, { createContext, FC, PropsWithChildren, useContext, useState } from 'react';

import { USER } from '../constants/windowStorageKeys';
import { authenticateZendeskUser, logoutZendeskUser } from '../helpers/zendeskConfig';
import { useZendeskAuth } from '../hooks/useZendeskAuth';
import { login, logout } from '../services/auth';
import { AccountType, PrimaryTimeZone } from '../services/types';

export interface AuthenticatedUser {
    id: string;
    customLogin: string | null;
    email: string;
    firstName: string;
    lastName: string;
    accountType: AccountType;
    joinedAt: string;
    fullName: string;
    lastSeen: string;
    isDeactivated: boolean;
    accountId: number;
    primaryTimeZone?: PrimaryTimeZone;
    permissions: {
        manageUsers: boolean;
        manageRidesBookedByOthers: boolean;
        seeInvoices: boolean;
    };
}

export interface AuthenticatedUserContextValue {
    user: AuthenticatedUser | null;
    onLogin: (email: string, password: string) => Promise<void>;
    onLogout: () => Promise<void>;
}

export const AuthContext = createContext<AuthenticatedUserContextValue | null>(null);

const getAuthenticatedUser = (): AuthenticatedUser | null => {
    const storedUser = localStorage.getItem(USER);

    return storedUser ? (JSON.parse(storedUser) as AuthenticatedUser) : null;
};

export const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
    const [user, setUser] = useState<AuthenticatedUser | null>(getAuthenticatedUser());

    useZendeskAuth(user);

    const handleLogin = async (email: string, password: string) => {
        const loggedUser = await login(email, password);

        try {
            authenticateZendeskUser();
            localStorage.setItem(USER, JSON.stringify(loggedUser));
            setUser(loggedUser);
        } catch (error) {
            console.error('Failed to authenticate with Zendesk:', error);
            localStorage.setItem(USER, JSON.stringify(loggedUser));
            setUser(loggedUser);
        }
    };

    const handleLogout = async () => {
        try {
            await logout();
            localStorage.removeItem(USER);
            setUser(null);
            logoutZendeskUser();
        } catch (error) {
            throw new Error('Unexpected logout error.');
        }
    };

    const value = {
        user,
        onLogin: handleLogin,
        onLogout: handleLogout,
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = (): AuthenticatedUserContextValue => {
    const authenticatedUserContext = useContext(AuthContext);

    if (authenticatedUserContext === null) {
        throw new Error('AuthenticatedUserContext should not be null');
    }

    return authenticatedUserContext;
};

export const useAccountType = (): AccountType => {
    const authenticatedUserContext = useContext(AuthContext);

    return authenticatedUserContext?.user?.accountType ?? AccountType.CUSTOMER;
};
