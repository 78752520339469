/* eslint-disable complexity */
/* eslint-disable max-statements */
import {
    MultipleSelect,
    PrimaryButton,
    Spinner,
    TertiaryButton,
    TextField,
    NoResultsDataGrid as NoResults,
} from '@get-e/react-components';
import AddIcon from '@mui/icons-material/Add';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import FilterList from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import { Box, Grid, InputAdornment, Typography, useMediaQuery } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { DataGridPro, GridColDef, GridRowParams } from '@mui/x-data-grid-pro';
import { DateRange, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField/SingleInputDateRangeField';
import clsx from 'clsx';
import dayjs, { Dayjs } from 'dayjs';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useEffect, useMemo, useState, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

import { logAmplitudeEvent } from '../../../amplitude/amplitude';
import { RIDES_PAGE } from '../../../constants/amplitude/commonKeys';
import { COLORS } from '../../../constants/colors';
import { DATE_FORMATS } from '../../../constants/dateFormats';
import { datePickerPlaceholder } from '../../../constants/datePickerPlaceholder';
import { BUTTON_WIDTH, MIN_BUTTON_WIDTH } from '../../../constants/layout';
import { BOOKING_TOOL, getSingleRideRoute, getUpdateRideRoute } from '../../../constants/urlPaths';
import { RIDES_FILTER } from '../../../constants/windowStorageKeys';
import { useCurrentProfileContext } from '../../../context/CurrentProfileContext';
import { useLocaleContext } from '../../../context/LocaleContext';
import { Severity, useNotificationContext } from '../../../context/NotificationContext';
import { mapRidesToRows } from '../../../helpers/maps/mapRidesToRows';
import {
    getCustomerRidesFilter,
    getRideStatusKey,
    resetCustomerRidesFilter,
    RideStatusFilter,
    useRideStatusMap,
} from '../../../helpers/rideStatusUtil';
import useDataGridStyles from '../../../styles/DataGrid';
import theme from '../../../styles/theme';
import SendBookingConfirmationModal, { BookingConfirmationEmail } from '../../ride/components/SendBookingConfirmationModal';
import exportRidesToExcel from '../api/exportRidesToExcel';
import { Passenger } from '../api/types';
import { useRides } from '../api/useRides';
import { CustomFooter } from '../components/CustomFooter';
import RidesLoadingSkeleton from '../components/RidesLoadingSkeleton';
import RidesMobileFiltersModal from '../components/RidesMobileFiltersModal';
import { shortcutsItems } from '../supplier/helpers/dateRangeShortcuts';
import { handleRideMiddleMouseClick } from '../supplier/helpers/handleRideMiddleMouseClick';
import { BookingConfirmationRow, useRidesColumns } from './hooks/useRidesColumns';

const useStyles = ({ isButtonWidthFitContent }: { isButtonWidthFitContent: boolean }) =>
    makeStyles(styleTheme => ({
        addRideButton: {
            width: isButtonWidthFitContent ? 'fit-content' : BUTTON_WIDTH,
            minWidth: isButtonWidthFitContent ? MIN_BUTTON_WIDTH : 'auto',
        },
        footer: {
            display: 'flex',
            justifyContent: 'space-between',
            padding: '0 2rem',
        },
        filterButtonsWrapper: {
            paddingTop: '2rem',
            display: 'flex',
            flexDirection: 'row',
            [styleTheme.breakpoints.down('md')]: { flexDirection: 'column' },
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        multipleSelectButtonsWrapper: {
            overflowX: 'auto',
            whiteSpace: 'nowrap',
            marginBottom: 0,
            '&::-webkit-scrollbar': {
                height: 0,
                width: 0,
                display: 'none',
            },
            '& [class*="MuiButtonBase-root"]': {
                borderRight: '1px solid rgba(0, 0, 0, 0.12)',
                '&:nth-of-type(1)': {
                    marginLeft: 0,
                },
                marginLeft: '16px',
                borderRadius: '8px',
                cursor: 'pointer !important',
            },
        },
        resetFilters: {
            color: COLORS.BLUE,
            '&:hover': { color: COLORS.BLUE_DARK },
        },
        resultsFilterButtonWrapper: {
            display: 'flex',
            [styleTheme.breakpoints.down('md')]: { marginTop: '2rem' },
        },
        noResultsContentWrapper: {
            border: `1px solid ${COLORS.BLUE}`,
            borderRadius: '4px',
            padding: '0.625rem 1.25rem !important',
            marginTop: '2rem',
            display: 'flex',
            alignItems: 'center',
            '&:hover': {
                borderColor: COLORS.BLUE_DARK,
                cursor: 'pointer',
            },
        },
        totalCount: {
            padding: 0,
            width: 'auto',
        },
        totalCountLabel: {
            marginRight: 0,
            color: `${COLORS.SLATE_GREY} !important`,
        },
        filtersButton: {
            width: '57px',
            height: '55px',
            marginLeft: '10px',
            minWidth: '57px',
            borderRadius: '8px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '& [class*="MuiSvgIcon-root"]': {
                fontSize: '27px',
            },
        },
        search: {
            '& [class*="MuiFormLabel-root"]': {
                overflow: 'hidden',
                maxWidth: '83.5%',
            },
        },
    }));

export enum RideAction {
    EDIT_RIDE,
    SEND_CONFIRMATION,
    DOWNLOAD_CONFIRMATION,
    DOWNLOAD_RECEIPT,
}

const Rides = () => {
    const { t } = useTranslation();
    const { locale } = useLocaleContext();
    const { currentProfile: currentUser } = useCurrentProfileContext();
    const { showNotification } = useNotificationContext();
    const { useNewCustomerRidePage } = useFlags();
    const previousFilterState = getCustomerRidesFilter();
    const isPageOpened = useRef(false);
    const dataGridClases = useDataGridStyles();
    const history = useHistory();
    const isMobile = useMediaQuery(theme.breakpoints.down(1138));
    const classes = useStyles({ isButtonWidthFitContent: locale === 'es-ES' })();
    const rideStatusMap = useRideStatusMap();

    const [isSendBookingConfirmationModalOpen, setIsSendBookingConfirmationModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState<BookingConfirmationRow>();
    const [searchPhrase, setSearchPhrase] = useState('');
    const [statusFilterKey, setStatusFilterKey] = useState(0);
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const [isFiltersModalOpen, setIsFiltersModalOpen] = useState(false);

    const [statusIds, setStatusIds] = useState<string[]>(
        previousFilterState.status.map(value => getRideStatusKey(value) as string)
    );

    const [selectedDateRange, setSelectedDateRange] = useState<DateRange<Dayjs | null>>(previousFilterState.dateRange);

    const {
        data = [],
        isLoading,
        isRefetching,
    } = useRides({
        query: searchPhrase,
        statusFilters: statusIds?.filter(el => el),
        startDate: selectedDateRange[0]
            ? dayjs(selectedDateRange[0])
                  .set('hour', 0)
                  .set('minute', 0)
                  .set('second', 0)
                  .format(DATE_FORMATS['YYYY-MM-DDT00:00:00'])
            : null,
        endDate: selectedDateRange[1]
            ? dayjs(selectedDateRange[1])
                  .set('hour', 0)
                  .set('minute', 0)
                  .set('second', 0)
                  .format(DATE_FORMATS['YYYY-MM-DDT00:00:00'])
            : null,
    });

    useEffect(() => {
        if (!isLoading && isInitialLoad) {
            setIsInitialLoad(false);
        }
    }, [isLoading, isInitialLoad]);

    const onSetStatusIds = (ids: string[]): void => {
        const filters = JSON.stringify({
            ...previousFilterState,
            status: ids.map(value => RideStatusFilter[value] as string),
        });

        localStorage.setItem(RIDES_FILTER, filters);

        setStatusIds(ids);
    };

    useEffect(() => {
        document.addEventListener('mouseup', handleRideMiddleMouseClick);
        return () => document.removeEventListener('mouseup', handleRideMiddleMouseClick);
    }, [data]);

    const handleSendConfirmation = (row: BookingConfirmationRow) => {
        setSelectedRow(row);
        setIsSendBookingConfirmationModalOpen(true);
    };

    const handleRowClick = (params: GridRowParams, event: React.MouseEvent<HTMLElement>) => {
        if (event.button === 0 && event.metaKey) {
            event.preventDefault();
            window.open(getSingleRideRoute(params.id.toString()), '_blank');
            return;
        }

        history.push(getSingleRideRoute(params.id.toString()));
    };

    const handleEditRide = (rideId: string) => {
        useNewCustomerRidePage ? history.push(getUpdateRideRoute(rideId)) : history.push(getSingleRideRoute(rideId));
    };

    const columns = useRidesColumns(
        useCallback(handleSendConfirmation, []),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        useCallback(handleEditRide, []),
        currentUser?.primaryTimeZone
    );

    const { mutate: exportRidesToExcelMutation } = useMutation(exportRidesToExcel);

    const handleNewRide = (): void => {
        history.push(BOOKING_TOOL);
    };

    const handleExport = (): void => {
        const rideResultIds = data?.map(ride => ride.unid);

        if (selectedDateRange[0] === null || selectedDateRange[1] === null) {
            showNotification(t('alert.dateRangeRequired'), Severity.Error);
            return;
        }

        exportRidesToExcelMutation({
            startDate: dayjs(selectedDateRange[0]).format(DATE_FORMATS['YYYY-MM-DDTHH:mm:ss']),
            endDate: dayjs(selectedDateRange[1]).format(DATE_FORMATS['YYYY-MM-DDTHH:mm:ss']),
            tripUnids: rideResultIds,
        });
    };

    const initialBookingConfirmationEmails: BookingConfirmationEmail[] = useMemo(() => {
        return selectedRow?.passengers
            ? selectedRow?.passengers?.map((traveller: Passenger) => ({
                  id: traveller.email,
                  email: traveller.email,
                  isIncluded: traveller.isLead,
              }))
            : ([] as BookingConfirmationEmail[]);
    }, [selectedRow]);

    const dataRows = useMemo(() => {
        if (data) {
            return mapRidesToRows(data, currentUser);
        }

        return [];
    }, [currentUser, data]);

    const handleResetFilters = () => {
        const filters = resetCustomerRidesFilter();
        const filterStatusIds = filters.status.map(value => getRideStatusKey(value) as string);

        setSearchPhrase('');
        setStatusIds(filterStatusIds);
        setSelectedDateRange(filters.dateRange);
        setStatusFilterKey(Math.random());
    };

    const resetFiltersButton = useMemo(
        () => (
            <TertiaryButton className={classes.resetFilters} onClick={handleResetFilters}>
                <Box display="flex" alignItems="center">
                    <TuneOutlinedIcon style={{ paddingRight: '0.5rem' }} />
                    {t('buttonName.resetFilters')}
                </Box>
            </TertiaryButton>
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [handleResetFilters]
    );

    const filtersActive = useMemo(() => {
        return selectedDateRange.some(range => range !== null) || statusIds.some(status => Boolean(status));
    }, [selectedDateRange, statusIds]);

    if (!isPageOpened.current) {
        isPageOpened.current = true;
        logAmplitudeEvent(RIDES_PAGE);
    }

    if (isInitialLoad && isLoading) {
        return <RidesLoadingSkeleton />;
    }

    return (
        <Grid container alignItems="flex-start" padding={isMobile ? '2rem 1rem' : '0 2rem 2rem 2rem'}>
            <Grid container justifyContent="space-between" alignItems="flex-start">
                <Grid item>
                    <Typography
                        sx={{ padding: `${isMobile ? '1rem 0' : ''}`, color: COLORS.BLUE, fontSize: '1.5rem', fontWeight: 700 }}
                    >
                        {t('rides')}
                    </Typography>
                </Grid>
                <Grid item>
                    <PrimaryButton className={classes.addRideButton} onClick={handleNewRide} loading={false} icon={<AddIcon />}>
                        {t('pages.rides.newRide')}
                    </PrimaryButton>
                </Grid>
            </Grid>
            <Grid container sx={{ marginTop: '2rem' }}>
                <Grid
                    item
                    xs={12}
                    md={isMobile ? 12 : 8}
                    lg={isMobile ? 12 : 8}
                    xl={8}
                    marginBottom={isMobile ? '0.75rem' : 'initial'}
                    display="flex"
                    justifyContent="space-between"
                >
                    <TextField
                        style={{ marginBottom: 0 }}
                        type="text"
                        autoComplete="off"
                        value={searchPhrase}
                        noHelperTextSpace
                        onChange={event => {
                            setSearchPhrase(event.target.value);
                        }}
                        className={classes.search}
                        label={t('pages.rides.searchByPassengerReferenceRide')}
                        name="search"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {isLoading || isRefetching ? <Spinner size={16} /> : <SearchIcon />}
                                </InputAdornment>
                            ),
                            onBlur: event => setSearchPhrase(event.target.value),
                        }}
                        onBlur={event => setSearchPhrase(event.target.value)}
                    />
                    {isMobile && (
                        <Box
                            className={classes.filtersButton}
                            sx={{
                                backgroundColor: filtersActive ? COLORS.BLUE : COLORS.WHITE,
                                color: filtersActive ? COLORS.WHITE : COLORS.BLUE,
                                border: `1px solid ${filtersActive ? COLORS.BLUE : COLORS.BLACK_12}`,
                            }}
                            onClick={() => setIsFiltersModalOpen(true)}
                        >
                            <FilterList />
                        </Box>
                    )}
                </Grid>

                {!isMobile && (
                    <Grid item xs={12} className={classes.filterButtonsWrapper}>
                        <MultipleSelect
                            key={statusFilterKey}
                            classNames={{ buttonsWrapper: classes.multipleSelectButtonsWrapper }}
                            value={statusIds}
                            values={rideStatusMap}
                            onSetIds={onSetStatusIds}
                            defaultSelectedValue={previousFilterState.status?.map(filter => t(filter))}
                            displayAsButtons
                        />
                        <div className={classes.resultsFilterButtonWrapper}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateRangePicker
                                    sx={{ width: '100%', minWidth: '300px' }}
                                    label={selectedDateRange.every(dr => dr === null) ? t('pages.rides.allTime') : t('dateRange')}
                                    value={selectedDateRange}
                                    onChange={newValue => {
                                        setSelectedDateRange(newValue);

                                        const filters = JSON.stringify({
                                            ...previousFilterState,
                                            dateRange: newValue,
                                        });

                                        localStorage.setItem(RIDES_FILTER, filters);
                                    }}
                                    localeText={{
                                        start: t('pages.rides.startingDate'),
                                        end: t('pages.rides.endingDate'),
                                        ...datePickerPlaceholder,
                                    }}
                                    slotProps={{
                                        shortcuts: {
                                            items: shortcutsItems,
                                        },
                                        field: { clearable: true },
                                        textField: {
                                            variant: 'filled',
                                            placeholder: 'dd mmm y',
                                            inputProps: { readOnly: true },
                                            InputProps: {
                                                endAdornment: selectedDateRange.every(dr => dr === null) && (
                                                    <CalendarTodayOutlinedIcon style={{ color: COLORS.SLATE_GREY }} />
                                                ),
                                            },
                                        },
                                    }}
                                    slots={{ field: SingleInputDateRangeField }}
                                    format={DATE_FORMATS['DD MMM YYYY']}
                                />
                            </LocalizationProvider>
                        </div>
                    </Grid>
                )}
            </Grid>
            <Grid container>
                <Box
                    sx={{
                        width: '100%',
                        height: dataRows?.length ? `calc(100vh - ${isMobile ? '500px' : '300px'})` : 'auto',
                    }}
                >
                    <DataGridPro
                        style={{ height: dataRows?.length === 0 ? 'calc(100vh - 300px)' : '100%' }}
                        className={clsx({
                            [dataGridClases.dataGrid]: true,
                            [dataGridClases.font14]: true,
                            [dataGridClases.dataGridNoRows]: dataRows?.length === 0,
                        })}
                        hideFooterRowCount
                        disableColumnSelector
                        disableColumnFilter
                        rows={dataRows}
                        columns={columns as GridColDef[]}
                        getRowHeight={() => 'auto'}
                        onRowClick={handleRowClick}
                        loading={isLoading || !dataRows}
                        slots={{
                            noRowsOverlay: () => (
                                <NoResults
                                    text={t('pages.rides.noResults.text')}
                                    description={t('pages.rides.noResults.description')}
                                    additionalContent={
                                        <div className={classes.noResultsContentWrapper}>{resetFiltersButton}</div>
                                    }
                                />
                            ),
                            footer: () =>
                                dataRows?.length > 0
                                    ? CustomFooter({
                                          handleExport,
                                          totalCount: data.length,
                                      })
                                    : null,
                        }}
                        sx={{ overflowX: 'scroll' }}
                    />
                </Box>
            </Grid>
            {isSendBookingConfirmationModalOpen && (
                <SendBookingConfirmationModal
                    initialBookingConfirmationEmails={initialBookingConfirmationEmails}
                    isOpen={isSendBookingConfirmationModalOpen}
                    onClose={() => setIsSendBookingConfirmationModalOpen(false)}
                    rideId={selectedRow?.rideId ?? ''}
                />
            )}
            {isFiltersModalOpen && (
                <RidesMobileFiltersModal
                    onSubmit={(values: { dateRange: DateRange<Dayjs>; status: string[] }) => {
                        const filters = JSON.stringify({
                            status: values.status,
                            dateRange: values.dateRange,
                        });

                        localStorage.setItem(RIDES_FILTER, filters);

                        setStatusIds(values.status);
                        setSelectedDateRange(values.dateRange);
                    }}
                    statusFilterMap={rideStatusMap}
                    isOpen={isFiltersModalOpen}
                    onClose={() => setIsFiltersModalOpen(false)}
                    numberedValues={null}
                    page="CUSTOMER"
                />
            )}
        </Grid>
    );
};

export default Rides;
