import CachedIcon from '@mui/icons-material/Cached';
import SyncDisabledIcon from '@mui/icons-material/SyncDisabled';
import { Box, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { COLORS } from '../../../../constants/colors';
import theme from '../../../../styles/theme';

const useStyles = makeStyles({
    container: {
        height: '54px',
        backgroundColor: COLORS.MEDIUM_ORANGE,
        display: 'flex',
        alignItems: 'center',
        width: '100vw',
        marginLeft: '-435px',
        marginRight: '-500px',
    },
    containerMobile: {
        backgroundColor: COLORS.MEDIUM_ORANGE,
        alignItems: 'center',
        width: 'calc(100% + 40px)',
        marginLeft: '-33px',
        marginRight: '-33px',
        marginTop: '-15px',
        padding: '10px 25px',
    },
    boxOne: {
        color: COLORS.ORANGE_TEXT,
        fontWeight: '700',
        fontSize: '16px',
        display: 'flex',
        alignItems: 'center',
        marginRight: '10px',
    },
    boxThree: {
        color: COLORS.ORANGE_TEXT,
        textDecoration: 'underline',
        marginLeft: '10px',
        cursor: 'pointer',
    },
});

interface CarsAutomatedUpdatesNotificationProps {
    isIgnoredByCars?: boolean;
}

const CarsAutomatedUpdatesNotification = ({ isIgnoredByCars }: CarsAutomatedUpdatesNotificationProps) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    const isVerySmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleLearnMoreClick = () => {
        window.open('https://get-e.com/how-cars-works', '_blank', 'noopener,noreferrer');
    };

    return (
        <>
            <Box
                className={isMobile ? classes.containerMobile : classes.container}
                sx={{ height: isVerySmallScreen && isMobile ? '110px' : '95px' }}
            >
                <Box
                    className={classes.boxOne}
                    sx={{ marginLeft: isMobile ? '10px' : '19%', paddingTop: isMobile ? '15px' : '0' }}
                >
                    {!isIgnoredByCars ? (
                        <CachedIcon sx={{ marginRight: '5px' }} />
                    ) : (
                        <SyncDisabledIcon sx={{ marginRight: '5px' }} />
                    )}
                    {!isIgnoredByCars
                        ? t('pages.singleRide.carsAutomatedUpdates')
                        : t('pages.singleRide.ignoringAutomatedUpdates')}
                </Box>
                <Box sx={{ marginLeft: isMobile ? '10px' : 0 }}>
                    {!isIgnoredByCars
                        ? t('pages.singleRide.manualUpdateIgnoreResult')
                        : t('pages.singleRide.thisRideWasManuallyUpdated')}
                </Box>
                <Box onClick={handleLearnMoreClick} className={classes.boxThree}>
                    {t('pages.singleRide.learnHowItWorks')}
                </Box>
            </Box>
        </>
    );
};

export default CarsAutomatedUpdatesNotification;
