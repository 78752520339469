import { ModalAction as Modal } from '@get-e/react-components';
import CachedIcon from '@mui/icons-material/Cached';
import SyncDisabledIcon from '@mui/icons-material/SyncDisabled';
import { Typography, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { COLORS } from '../../../constants/colors';
import { CUSTOM_FIELD_KEY } from '../customer/Ride';

const useStyles = makeStyles({
    changesList: {
        marginTop: '0.5rem',
        paddingLeft: '1.5rem',
    },
    systemAutomatedBox: {
        padding: '.5rem',
        borderRadius: '.25rem',
        backgroundColor: COLORS.MEDIUM_ORANGE,
        marginBottom: '2rem',
    },
    contact: {
        textDecoration: 'underline',
        '&:hover': {
            color: COLORS.RED_WARNING,
            textDecoration: 'underline',
            borderBottom: 'none',
        },
    },
    boxOne: {
        color: COLORS.ORANGE_TEXT,
        fontWeight: '700',
        fontSize: '16px',
        display: 'flex',
        alignItems: 'center',
        marginLeft: '10px',
    },
    boxTwo: {
        marginLeft: '10px',
    },
    boxThree: {
        color: COLORS.ORANGE_TEXT,
        textDecoration: 'underline',
        marginLeft: '10px',
        cursor: 'pointer',
    },
});

const UpdateRideConfirmModal = ({
    onClose,
    onConfirm,
    changes,
    isLoading,
    isSystemAutomatedRide,
    isIgnoredByCars,
}: {
    onClose: () => void;
    onConfirm: () => void;
    changes?: Map<string, boolean | typeof CUSTOM_FIELD_KEY>;
    isLoading?: boolean;
    isSystemAutomatedRide?: boolean;
    isIgnoredByCars?: boolean;
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { useManagedByCars } = useFlags();

    const changedItems = useMemo(() => {
        const items: string[] = [];

        const changedItemElements: JSX.Element[] = [];

        changes?.forEach((isChanged, field) => {
            isChanged && items.push(field);

            if (isChanged === true || isChanged === CUSTOM_FIELD_KEY) {
                changedItemElements.push(
                    <li key={field}>{typeof isChanged === 'boolean' ? t(`pages.singleRide.updatedFields.${field}`) : field}</li>
                );
            }
        });

        return <ul className={classes.changesList}>{changedItemElements ?? <></>}</ul>;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [changes]);

    return (
        <Modal
            isOpen
            onClose={onClose}
            onSubmit={onConfirm}
            title={t('modals.updateRide.title')}
            confirmButtonLabel={t('buttonName.update')}
            cancelButtonLabel={t('buttonName.back')}
            maxWidth="sm"
            isDisabled={isLoading}
        >
            {isSystemAutomatedRide && useManagedByCars && (
                <Box className={classes.systemAutomatedBox}>
                    <Box className={classes.boxOne}>
                        {!isIgnoredByCars ? (
                            <CachedIcon sx={{ marginRight: '5px' }} />
                        ) : (
                            <SyncDisabledIcon sx={{ marginRight: '5px' }} />
                        )}
                        {!isIgnoredByCars
                            ? t('pages.singleRide.carsAutomatedUpdates')
                            : t('pages.singleRide.ignoringAutomatedUpdates')}
                    </Box>
                    <Box className={classes.boxTwo}>
                        {!isIgnoredByCars
                            ? t('pages.singleRide.manualUpdateIgnoreResult')
                            : t('pages.singleRide.thisRideWasManuallyUpdated')}
                    </Box>
                    <Box
                        className={classes.boxThree}
                        onClick={() => {
                            window.open('https://get-e.com/how-cars-works', '_blank');
                        }}
                    >
                        {t('pages.singleRide.learnHowItWorks')}
                    </Box>
                </Box>
            )}
            <Typography>{t('modals.updateRide.description')}</Typography>
            <Typography
                style={{
                    fontWeight: 700,
                    marginTop: '1rem',
                }}
            >
                {t('modals.updateRide.whatIsChanging')}
            </Typography>
            {changedItems}
            <Typography
                style={{
                    fontWeight: 700,
                    marginTop: '1rem',
                }}
            >
                {t('doYouWantToProceed')}
            </Typography>
        </Modal>
    );
};

export default UpdateRideConfirmModal;
