import { ModalAction as Modal } from '@get-e/react-components';
import CachedIcon from '@mui/icons-material/Cached';
import SyncDisabledIcon from '@mui/icons-material/SyncDisabled';
import { Typography, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { COLORS } from '../../../constants/colors';
import { CancellationPolicy } from '../api/types';
import { Vehicle } from './VehicleInformationCards';

const useStyles = makeStyles({
    confirmButton: {
        background: COLORS.RED,
        '&:hover': { background: COLORS.RED_WARNING },
    },
    closeButton: { marginLeft: '.5rem' },
    systemAutomatedBox: {
        padding: '.5rem',
        borderRadius: '.25rem',
        backgroundColor: COLORS.MEDIUM_ORANGE,
        marginBottom: '.5rem',
    },
    boxOne: {
        color: COLORS.ORANGE_TEXT,
        fontWeight: '700',
        fontSize: '16px',
        display: 'flex',
        alignItems: 'center',
        marginLeft: '10px',
    },
    boxTwo: {
        marginLeft: '10px',
    },
    boxThree: {
        color: COLORS.ORANGE_TEXT,
        textDecoration: 'underline',
        marginLeft: '10px',
        cursor: 'pointer',
    },
});

const CancelRideConfirmModal = ({
    onClose,
    onConfirm,
    cancellationPolicies,
    selectedVehicle,
    isLoading,
    isSystemAutomatedRide,
    isIgnoredByCars,
}: {
    onClose: () => void;
    onConfirm: () => void;
    cancellationPolicies: CancellationPolicy[];
    selectedVehicle: Vehicle | null;
    isLoading?: boolean;
    isSystemAutomatedRide?: boolean;
    isIgnoredByCars?: boolean;
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { useManagedByCars } = useFlags();

    return (
        <Modal
            isOpen
            onClose={onClose}
            onSubmit={onConfirm}
            title={t('modals.cancelRide.title')}
            confirmButtonLabel={t('buttonName.cancel')}
            cancelButtonLabel={t('buttonName.back')}
            confirmButtonClassName={classes.confirmButton}
            maxWidth="sm"
            isDisabled={isLoading}
        >
            {isSystemAutomatedRide && useManagedByCars && (
                <Box className={classes.systemAutomatedBox}>
                    <Box className={classes.systemAutomatedBox}>
                        <Box className={classes.boxOne}>
                            {!isIgnoredByCars ? (
                                <CachedIcon sx={{ marginRight: '5px' }} />
                            ) : (
                                <SyncDisabledIcon sx={{ marginRight: '5px' }} />
                            )}
                            {!isIgnoredByCars
                                ? t('pages.singleRide.carsAutomatedUpdates')
                                : t('pages.singleRide.ignoringAutomatedUpdates')}
                        </Box>
                        <Box className={classes.boxTwo}>
                            {!isIgnoredByCars
                                ? t('pages.singleRide.manualUpdateIgnoreResult')
                                : t('pages.singleRide.thisRideWasManuallyUpdated')}
                        </Box>
                        <Box
                            className={classes.boxThree}
                            onClick={() => {
                                window.open('https://get-e.com/how-cars-works', '_blank');
                            }}
                        >
                            {t('pages.singleRide.learnHowItWorks')}
                        </Box>
                    </Box>
                </Box>
            )}
            <Typography>{t('modals.cancelRide.description')}</Typography>
            {cancellationPolicies.length > 0 && (
                <Typography
                    style={{
                        fontWeight: 700,
                        marginBottom: 0,
                        marginTop: '1rem',
                    }}
                >
                    {t('pages.singleRide.polices.cancellationPolicies')}
                </Typography>
            )}
            {cancellationPolicies.map(policy => (
                <div key={policy.id}>
                    <Typography key={policy.id} mb={'1rem'}>
                        {t('pages.singleRide.polices.cancelled', {
                            cancelledUnderHours: policy.cancelledUnderHours,
                            chargePercentage: policy.chargePercentage,
                        })}
                    </Typography>
                    {selectedVehicle && policy.vehicleTypes.includes(selectedVehicle.vehicleType) && (
                        <Typography key={policy.id} mb={'1rem'}>
                            {t('pages.singleRide.polices.appliableFor', { appliableCarTypes: selectedVehicle.vehicleType })}
                        </Typography>
                    )}
                </div>
            ))}
            <Typography fontWeight="700" marginTop="1rem">
                {t('doYouWantToProceed')}
            </Typography>
        </Modal>
    );
};

export default CancelRideConfirmModal;
