import { useEffect } from 'react';

import { AuthenticatedUser } from '../context/AuthenticatedUserContext';
import { authenticateZendeskUser } from '../helpers/zendeskConfig';

export const useZendeskAuth = (user: AuthenticatedUser | null) => {
    useEffect(() => {
        const checkZendeskAvailable = (): Promise<void> => {
            return new Promise(resolve => {
                if (window.zE) {
                    resolve();
                    return;
                }

                const maxAttempts = 10;
                let attempts = 0;

                const intervalId = setInterval(() => {
                    attempts++;

                    if (window.zE || attempts >= maxAttempts) {
                        clearInterval(intervalId);
                        resolve();
                    }
                }, 500);
            });
        };

        const initZendesk = async () => {
            try {
                await checkZendeskAvailable();

                if (user && window.zE) {
                    authenticateZendeskUser();
                }
            } catch (error) {
                console.error('Failed to initialize Zendesk:', error);
            }
        };

        initZendesk().catch(error => {
            console.error('Zendesk initialization failed:', error);
        });
    }, [user]);
};
