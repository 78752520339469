/* eslint-disable max-statements */
import {
    MultipleSelect,
    PrimaryButton,
    Spinner,
    TertiaryButton,
    TextField,
    NoResultsDataGrid as NoResults,
} from '@get-e/react-components';
import AddIcon from '@mui/icons-material/Add';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import SearchIcon from '@mui/icons-material/Search';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import { Box, Grid, InputAdornment, Typography, useMediaQuery } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { DataGridPro, GridColDef, GridRowParams } from '@mui/x-data-grid-pro';
import { DateRange, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField/SingleInputDateRangeField';
import clsx from 'clsx';
import dayjs, { Dayjs } from 'dayjs';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useEffect, useMemo, useState, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

import { logAmplitudeEvent } from '../../../amplitude/amplitude';
import { RIDES_PAGE } from '../../../constants/amplitude/commonKeys';
import { COLORS } from '../../../constants/colors';
import { DATE_FORMATS } from '../../../constants/dateFormats';
import { datePickerPlaceholder } from '../../../constants/datePickerPlaceholder';
import { BUTTON_WIDTH, MIN_BUTTON_WIDTH } from '../../../constants/layout';
import { BOOKING_TOOL, getSingleRideRoute, getUpdateRideRoute } from '../../../constants/urlPaths';
import { RIDES_FILTER } from '../../../constants/windowStorageKeys';
import { useCurrentProfileContext } from '../../../context/CurrentProfileContext';
import { useLocaleContext } from '../../../context/LocaleContext';
import { Severity, useNotificationContext } from '../../../context/NotificationContext';
import { mapRidesToRows } from '../../../helpers/maps/mapRidesToRows';
import {
    getCustomerRidesFilter,
    getRideStatusKey,
    resetCustomerRidesFilter,
    RideStatusFilter,
    useRideStatusMap,
} from '../../../helpers/rideStatusUtil';
import useDataGridStyles from '../../../styles/DataGrid';
import theme from '../../../styles/theme';
import SendBookingConfirmationModal, { BookingConfirmationEmail } from '../../ride/components/SendBookingConfirmationModal';
import exportRidesToExcel from '../api/exportRidesToExcel';
import { Passenger } from '../api/types';
import { useRides } from '../api/useRides';
import { CustomFooter } from '../components/CustomFooter';
import RidesLoadingSkeleton from '../components/RidesLoadingSkeleton';
import { handleRideMiddleMouseClick } from '../supplier/helpers/handleRideMiddleMouseClick';
import { BookingConfirmationRow, useRidesColumns } from './hooks/useRidesColumns';

const useStyles = ({ isButtonWidthFitContent }: { isButtonWidthFitContent: boolean }) =>
    makeStyles(styleTheme => ({
        addRideButton: {
            width: isButtonWidthFitContent ? 'fit-content' : BUTTON_WIDTH,
            minWidth: isButtonWidthFitContent ? MIN_BUTTON_WIDTH : 'auto',
        },
        footer: {
            display: 'flex',
            justifyContent: 'space-between',
            padding: '0 2rem',
        },
        filterButtonsWrapper: {
            paddingTop: '2rem',
            display: 'flex',
            flexDirection: 'row',
            [styleTheme.breakpoints.down('md')]: { flexDirection: 'column', overflow: 'auto' },
            justifyContent: 'space-between',
        },
        multipleSelectButtonsWrapper: {
            overflowX: 'auto',
            whiteSpace: 'nowrap',
            marginBottom: 0,
            '&::-webkit-scrollbar': {
                height: 0,
                width: 0,
                display: 'none',
            },
        },
        resetFilters: {
            color: COLORS.BLUE,
            '&:hover': { color: COLORS.BLUE_DARK },
        },
        resultsFilterButtonWrapper: {
            display: 'flex',
            [styleTheme.breakpoints.down('md')]: { marginTop: '2rem' },
        },
        noResultsContentWrapper: {
            border: `1px solid ${COLORS.BLUE}`,
            borderRadius: '4px',
            padding: '0.625rem 1.25rem !important',
            marginTop: '2rem',
            display: 'flex',
            alignItems: 'center',
            '&:hover': {
                borderColor: COLORS.BLUE_DARK,
                cursor: 'pointer',
            },
        },
        totalCount: {
            padding: 0,
            width: 'auto',
        },
        totalCountLabel: {
            marginRight: 0,
            color: `${COLORS.SLATE_GREY} !important`,
        },
    }));

export enum RideAction {
    EDIT_RIDE,
    SEND_CONFIRMATION,
    DOWNLOAD_CONFIRMATION,
    DOWNLOAD_RECEIPT,
}

const Rides = () => {
    const previousFilterState = getCustomerRidesFilter();
    const { t } = useTranslation();
    const { locale } = useLocaleContext();
    const classes = useStyles({ isButtonWidthFitContent: locale === 'es-ES' })();
    const history = useHistory();
    const dataGridClases = useDataGridStyles();
    const { showNotification } = useNotificationContext();
    const [isSendBookingConfirmationModalOpen, setIsSendBookingConfirmationModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState<BookingConfirmationRow>();
    const [searchPhrase, setSearchPhrase] = useState('');
    const [statusFilterKey, setStatusFilterKey] = useState(0);
    const { currentProfile: currentUser } = useCurrentProfileContext();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isPageOpened = useRef(false);
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const { useNewCustomerRidePage } = useFlags();
    const rideStatusMap = useRideStatusMap();

    const [statusIds, setStatusIds] = useState<string[]>(
        previousFilterState.status.map(value => getRideStatusKey(value) as string)
    );

    const [selectedDateRange, setSelectedDateRange] = useState<DateRange<Dayjs | null>>(previousFilterState.dateRange);

    const {
        data = [],
        isLoading,
        isRefetching,
    } = useRides({
        query: searchPhrase,
        statusFilters: statusIds?.filter(el => el),
        startDate: selectedDateRange[0]
            ? dayjs(selectedDateRange[0])
                  .set('hour', 0)
                  .set('minute', 0)
                  .set('second', 0)
                  .format(DATE_FORMATS['YYYY-MM-DDT00:00:00'])
            : null,
        endDate: selectedDateRange[1]
            ? dayjs(selectedDateRange[1])
                  .set('hour', 0)
                  .set('minute', 0)
                  .set('second', 0)
                  .format(DATE_FORMATS['YYYY-MM-DDT00:00:00'])
            : null,
    });

    useEffect(() => {
        if (!isLoading && isInitialLoad) {
            setIsInitialLoad(false);
        }
    }, [isLoading, isInitialLoad]);

    const onSetStatusIds = (ids: string[]): void => {
        const filters = JSON.stringify({
            ...previousFilterState,
            status: ids.map(value => RideStatusFilter[value] as string),
        });

        localStorage.setItem(RIDES_FILTER, filters);

        setStatusIds(ids);
    };

    useEffect(() => {
        document.addEventListener('mouseup', handleRideMiddleMouseClick);
        return () => document.removeEventListener('mouseup', handleRideMiddleMouseClick);
    }, [data]);

    const handleSendConfirmation = (row: BookingConfirmationRow) => {
        setSelectedRow(row);
        setIsSendBookingConfirmationModalOpen(true);
    };

    const handleRowClick = (params: GridRowParams, event: React.MouseEvent<HTMLElement>) => {
        if (event.button === 0 && event.metaKey) {
            event.preventDefault();
            window.open(getSingleRideRoute(params.id.toString()), '_blank');
            return;
        }

        history.push(getSingleRideRoute(params.id.toString()));
    };

    const handleEditRide = (rideId: string) => {
        useNewCustomerRidePage ? history.push(getUpdateRideRoute(rideId)) : history.push(getSingleRideRoute(rideId));
    };

    const columns = useRidesColumns(
        useCallback(handleSendConfirmation, []),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        useCallback(handleEditRide, []),
        currentUser?.primaryTimeZone
    );

    const { mutate: exportRidesToExcelMutation } = useMutation(exportRidesToExcel);

    const handleNewRide = (): void => {
        history.push(BOOKING_TOOL);
    };

    const handleExport = (): void => {
        const rideResultIds = data?.map(ride => ride.unid);

        if (selectedDateRange[0] === null || selectedDateRange[1] === null) {
            showNotification(t('alert.dateRangeRequired'), Severity.Error);
            return;
        }

        exportRidesToExcelMutation({
            startDate: dayjs(selectedDateRange[0]).format(DATE_FORMATS['YYYY-MM-DDTHH:mm:ss']),
            endDate: dayjs(selectedDateRange[1]).format(DATE_FORMATS['YYYY-MM-DDTHH:mm:ss']),
            tripUnids: rideResultIds,
        });
    };

    const initialBookingConfirmationEmails: BookingConfirmationEmail[] = useMemo(() => {
        return selectedRow?.passengers
            ? selectedRow?.passengers?.map((traveller: Passenger) => ({
                  id: traveller.email,
                  email: traveller.email,
                  isIncluded: traveller.isLead,
              }))
            : ([] as BookingConfirmationEmail[]);
    }, [selectedRow]);

    const dataRows = useMemo(() => {
        if (data) {
            return mapRidesToRows(data, currentUser);
        }

        return [];
    }, [currentUser, data]);

    const handleResetFilters = () => {
        const filters = resetCustomerRidesFilter();
        const filterStatusIds = filters.status.map(value => getRideStatusKey(value) as string);

        setSearchPhrase('');
        setStatusIds(filterStatusIds);
        setSelectedDateRange(filters.dateRange);
        setStatusFilterKey(Math.random());
    };

    const resetFiltersButton = useMemo(
        () => (
            <TertiaryButton className={classes.resetFilters} onClick={handleResetFilters}>
                <Box display="flex" alignItems="center">
                    <TuneOutlinedIcon style={{ paddingRight: '0.5rem' }} />
                    {t('buttonName.resetFilters')}
                </Box>
            </TertiaryButton>
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [handleResetFilters]
    );

    if (!isPageOpened.current) {
        isPageOpened.current = true;
        logAmplitudeEvent(RIDES_PAGE);
    }

    if (isInitialLoad && isLoading) {
        return <RidesLoadingSkeleton />;
    }

    return (
        <Grid container alignItems="flex-start" padding={isMobile ? '2rem 1rem' : '0 2rem 2rem 2rem'}>
            <Grid container justifyContent="space-between" alignItems="flex-start">
                <Grid item>
                    <Typography
                        sx={{ padding: `${isMobile ? '1rem 0' : ''}`, color: COLORS.BLUE, fontSize: '1.5rem', fontWeight: 700 }}
                    >
                        {t('rides')}
                    </Typography>
                </Grid>
                <Grid item>
                    <PrimaryButton className={classes.addRideButton} onClick={handleNewRide} loading={false} icon={<AddIcon />}>
                        {t('pages.rides.newRide')}
                    </PrimaryButton>
                </Grid>
            </Grid>
            <Grid container sx={{ marginTop: '2rem' }}>
                <Grid item xs={12} md={8} xl={8} marginBottom={isMobile ? '1rem' : '0'}>
                    <TextField
                        style={{ marginBottom: 0 }}
                        type="text"
                        autoComplete="off"
                        value={searchPhrase}
                        noHelperTextSpace
                        onChange={event => {
                            setSearchPhrase(event.target.value);
                        }}
                        label={t('pages.rides.searchByPassengerReferenceRide')}
                        name="search"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {isLoading || isRefetching ? <Spinner size={16} /> : <SearchIcon />}
                                </InputAdornment>
                            ),
                            onBlur: event => setSearchPhrase(event.target.value),
                        }}
                        onBlur={event => setSearchPhrase(event.target.value)}
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={4}
                    xl={4}
                    sx={{
                        paddingLeft: ['0', '0', '2rem'],
                        marginTop: ['1rem', '1rem', 0],
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}
                >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateRangePicker
                            sx={{ width: '100%' }}
                            label={t('dateRange')}
                            value={selectedDateRange}
                            onChange={newValue => {
                                setSelectedDateRange(newValue);

                                const filters = JSON.stringify({
                                    ...previousFilterState,
                                    dateRange: newValue,
                                });

                                localStorage.setItem(RIDES_FILTER, filters);
                            }}
                            localeText={{
                                start: t('pages.rides.startingDate'),
                                end: t('pages.rides.endingDate'),
                                ...datePickerPlaceholder,
                            }}
                            slotProps={{
                                textField: {
                                    variant: 'filled',
                                    placeholder: 'dd mmm y',
                                    inputProps: { readOnly: true },
                                    InputProps: {
                                        endAdornment: <CalendarTodayOutlinedIcon style={{ color: COLORS.SLATE_GREY }} />,
                                    },
                                },
                            }}
                            slots={{ field: SingleInputDateRangeField }}
                            format={DATE_FORMATS['DD MMM YYYY']}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12} className={classes.filterButtonsWrapper}>
                    <MultipleSelect
                        key={statusFilterKey}
                        classNames={{ buttonsWrapper: classes.multipleSelectButtonsWrapper }}
                        value={statusIds}
                        values={rideStatusMap}
                        onSetIds={onSetStatusIds}
                        defaultSelectedValue={previousFilterState.status?.map(filter => t(filter))}
                        displayAsButtons
                    />
                    <div className={classes.resultsFilterButtonWrapper}>{resetFiltersButton}</div>
                </Grid>
            </Grid>
            <Grid container>
                <Box
                    sx={{
                        width: '100%',
                        height: dataRows?.length ? `calc(100vh - ${isMobile ? '500px' : '300px'})` : 'auto',
                    }}
                >
                    <DataGridPro
                        style={{ height: dataRows?.length === 0 ? 'calc(100vh - 300px)' : '100%' }}
                        className={clsx({
                            [dataGridClases.dataGrid]: true,
                            [dataGridClases.font14]: true,
                            [dataGridClases.dataGridNoRows]: dataRows?.length === 0,
                        })}
                        hideFooterRowCount
                        disableColumnSelector
                        disableColumnFilter
                        rows={dataRows}
                        columns={columns as GridColDef[]}
                        getRowHeight={() => 'auto'}
                        onRowClick={handleRowClick}
                        loading={isLoading || !dataRows}
                        slots={{
                            noRowsOverlay: () => (
                                <NoResults
                                    text={t('pages.rides.noResults.text')}
                                    description={t('pages.rides.noResults.description')}
                                    additionalContent={
                                        <div className={classes.noResultsContentWrapper}>{resetFiltersButton}</div>
                                    }
                                />
                            ),
                            footer: () =>
                                dataRows?.length > 0
                                    ? CustomFooter({
                                          handleExport,
                                          totalCount: data.length,
                                      })
                                    : null,
                        }}
                        sx={{ overflowX: 'scroll' }}
                    />
                </Box>
            </Grid>
            {isSendBookingConfirmationModalOpen && (
                <SendBookingConfirmationModal
                    initialBookingConfirmationEmails={initialBookingConfirmationEmails}
                    isOpen={isSendBookingConfirmationModalOpen}
                    onClose={() => setIsSendBookingConfirmationModalOpen(false)}
                    rideId={selectedRow?.rideId ?? ''}
                />
            )}
        </Grid>
    );
};

export default Rides;
