import { Point } from '../../../components/navigation/GoogleMap';
import { RideStatus, RideTrackingStatus } from '../../../helpers/rideStatusUtil';
import { Driver } from '../../drivers/api/types';
import { Arrival, Departure, FlightDetails } from '../../rides/api/types';
import { UpdateTraveller } from '../components/travellerInformation/TravellerInformation';

export enum FeedbackTopics {
    SERVICE_RATE = 'SERVICE_RATE',
    DRIVER_RATE = 'DRIVER_RATE',
    MEETING_DRIVER_RATE = 'MEETING_DRIVER_RATE',
    VEHICLE_RATE = 'VEHICLE_RATE',
}

export interface RideFeedbackTopic {
    topic: FeedbackTopics;
    rate: number;
}
export interface RideFeedback {
    averageRate: number;
    hidden: boolean;
    topics: RideFeedbackTopic[];
    comment: string;
}

export interface CustomField {
    id: number;
    label: string;
    key: string;
    value: string;
    isRequired: boolean;
}

export type CustomFieldItem = [string, string];

export interface RideResponse {
    unid: string;
    prettifiedUnid: string;
    status: keyof typeof RideStatus;
    trackingStatus: keyof typeof RideTrackingStatus;
    distanceInMeters: number;
    driverVehicleId: number | null;
    durationInSeconds: number;
    numberOfPassengers: number;
    numberOfBags: number;
    passengers: Passenger[];
    vehicle: Vehicle;
    dropOff: Arrival;
    pickUp: Departure;
    specialRequest: string;
    customerReference: string | null;
    supplierReference: string | null;
    supplier: string;
    customer: string;
    appliedCancellationPolicy?: CancellationPolicy | null;
    cancellationPolicies: CancellationPolicy[];
    driver: Driver | null;
    flightDetails: FlightDetails | null;
    paymentMethod?: string;
    booker?: Booker;
    pickupDescription?: string;
    cancelledAt: string | null;
    feedbacks: RideFeedback[];
    lastPositionUpdate?: Point;
    customFields?: CustomField[];
    price: Pricing;
    notesForTheCustomer?: string;
    notesToSupplier?: string;
    contactPerson?: Partial<Passenger> | null;
    createdAt: string;
    situationTag: string;
    qcStatus: boolean;
    isManagedByApi?: boolean;
    isIgnoredByCars?: boolean;
}

export interface Booker {
    id: number;
    name: string;
}

export interface Passenger {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    isLead: boolean;
    uuid: string;
}

export interface Vehicle {
    name: string;
    class: string;
    type: string;
    brandAndModel?: string;
    imageUrl: string;
    seats: number;
    luggage: number;
    id?: number;
    licensePlate?: string;
    identifier?: string;
    color?: string;
    status?: string;
    isArchived?: boolean;
}

export interface Pricing {
    isoCurrency: string;
    amount: number;
}

export interface CancellationPolicy {
    id: number;
    chargePercentage: number;
    cancelledUnderHours: number;
    distanceExceedsKm?: number | null;
    vehicleTypes: string[];
    vehicleClasses?: string[];
}

export interface UpdateRideInformation {
    id: string;
    payload: {
        numberOfPassengers: number;
        numberOfBags: number;
        customerReference: string;
        specialRequest: string;
        customFields: { [key: string]: string } | null;
    };
}

export interface UpdateTravellerInformation {
    id: string;
    payload: UpdateTraveller[];
}

export interface UpdateAvailabilites {
    pickup: {
        transferPoint: {
            id: string;
            type: string;
        };
        pickupTimeZulu: string;
        pickupTime: string;
    };
    dropOff: {
        transferPoint: {
            id: string;
            type: string;
        };
    };
    numberOfPassengers: number;
    numberOfBags: number;
}

export interface SearchLocation {
    id: string;
    name: string;
    address?: string;
    type: string;
    city?: string;
    country?: string;
    latitude: number;
    longitude: number;
    timeZone?: string;
}

export interface UpdateVehicleRide {
    rideId: string;
    carId: string;
}

export interface FlightDetailsVariables {
    id: string;
    payload: {
        flightNumber: string;
        departureDate: string;
    };
}

export enum PAYMENT_METHOD {
    CREDIT_CARD = 'Creditcard',
    INVOICE = 'Invoice',
}

export interface AvailabilitiesResponse {
    estimationDetails: EstimationDetails;
    availabilities: VehicleResponse[];
}

export interface EstimationDetails {
    distanceInMeters: number;
    durationInSeconds: number;
}

export interface VehicleResponse {
    uuid: string;
    vehicleName: string;
    vehicleClass: string;
    vehicleType: string;
    vehicleImage: string;
    vehicleAttributes: string[];
    vehicleSeatsNumber: number;
    vehicleBagsCapacity: number;
    price: number;
    currency: string;
}

export interface ConfirmCloseAddRemarksRideRequest {
    referenceNumber?: string;
    minutesWaited?: number;
    minutesCosts?: number;
    parkingCosts?: number;
    otherCosts?: number;
    remarks?: string;
}

export interface RideAmplitudeEventProperties {
    'Ride number': string;
    'Ride created time': string;
    'Ride pickup time': string;
    'Ride customer': string;
    'Ride supplier': string;
    'Ride status': string;
    'Ride situation tags': string;
    'Ride qc status': boolean;
    'Ride has driver': boolean;
}
